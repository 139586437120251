<router>
{
    name: 'Sales Tool Bonds',
}
</router>
<template lang="pug">
v-container
    v-row
        v-spacer
        v-col
            Bonds
        v-spacer

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import Bonds from '@/components/salestool/bonds.vue';
export default {
    meta: {
        role: 'sat'
    },
    components: {
        Bonds
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
