<template lang="pug">
v-card(light, flat)
    v-card-text
        v-text-field(label='Rate', type='number', v-model.number='bondRate', suffix='%', @change='updateSettings', color='brand')
        v-text-field(label='Overhead', type='number', v-model.number='overhead', suffix='%', @change='updateSettings', color='brand')
        v-text-field(label='Profit', type='number', v-model.number='profit', suffix='%', @change='updateSettings', color='brand')

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)
</template>
f
<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satbonds',
            message: '',
            busy: false,

            bondRate: 0,
            overhead: 0,
            profit: 0,
            settingsId: ''
        };
    },
    methods: {
        updateSettings () {
            let settings = {
                bond_overhead: this.overhead,
                bond_profit: this.profit,
                bond_rate: this.bondRate,
                id: this.settingsId
            };

            this.doUpdateSettings(settings);
        },
        async doUpdateSettings (settings) {
            let result = await this.sendCommand({
                action: 'updateSettings',
                parameters: settings
            });
            if (result) {
                this.message = `Bond settings updated successfully`;
            } else {
                console.log('error updating bond settings');
                this.message = `Error updating bond settings`;
            }
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
    },
    watch: {
    },
    mounted () {
        this.socketEmit('getSettings', null, (results) => {
            let settings = results[0];
            this.bondRate = settings.bond_rate;
            this.overhead = settings.bond_overhead;
            this.profit = settings.bond_profit;
            this.settingsId = settings.id;
        });
        window.satbondsvm = this;
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">

</style>
